import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ForbiddenComponent} from './components/forbidden/forbidden.component';
import {GatewayTimeoutComponent} from './components/gateway-timeout/gateway-timeout.component';
import {InternalServerErrorComponent} from './components/internal-server-error/internal-server-error.component';
import {NotFoundComponent} from './components/not-found/not-found.component';

const routes: Routes = [
	{
		path: 'forbidden',
		component: ForbiddenComponent,
		data: {title: 'Недостаточно прав для просмотра ресурсв'}
	},
	{
		path: 'gateway-timeout',
		component: GatewayTimeoutComponent,
		data: {title: 'Ошибка сервера, попробуйте позже'}
	},
	{
		path: 'server-error',
		component: InternalServerErrorComponent,
		data: {title: 'Ошибка сервера'}
	},
	{
		path: 'not-found',
		component: NotFoundComponent,
		data: {title: 'Ресурс не найден'}
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)]
})
export class HttpErrorsRouting {
}
