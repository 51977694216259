<div class="container">
	<div class="error">
		<div class="status">
			404
		</div>
		<div class="description">
			Страница не найдена
		</div>
		<div class="actions">
			<ul>
				<li><a href="/">Перейти на главную</a></li>
				<li><a href="mailto:owl_scada@kalashnikovconcern.ru?subject=Scada unexpected feature">Написать в
					поддержку</a></li>
			</ul>
		</div>
	</div>
</div>

