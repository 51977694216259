<div class="container">
	<div class="error">
		<div class="status">
			500
		</div>
		<div class="description">
			Ошибка сервера
		</div>
		<div class="actions">
			<ul>
				<li><a href="mailto:owl_scada@kalashnikovconcern.ru?subject=Scada unexpected feature">Написать в
					поддержку</a></li>
			</ul>
		</div>
	</div>
</div>
