import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ForbiddenComponent} from './components/forbidden/forbidden.component';
import {GatewayTimeoutComponent} from './components/gateway-timeout/gateway-timeout.component';
import {InternalServerErrorComponent} from './components/internal-server-error/internal-server-error.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {HttpErrorsRouting} from './http-errors.routing';

@NgModule({
	declarations: [
		ForbiddenComponent,
		GatewayTimeoutComponent,
		InternalServerErrorComponent,
		NotFoundComponent
	],
	imports: [
		HttpErrorsRouting,
		CommonModule
	],
	exports: [
		ForbiddenComponent,
		GatewayTimeoutComponent,
		InternalServerErrorComponent,
		NotFoundComponent
	]
})
export class HttpErrorsModule {
}
